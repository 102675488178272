.logo {
	img {
		max-width: 113px;
		width: 100%;
		margin-left: 0;
		display: inline-block;
	}
	span {
		color: $greylight;
		font-size: 14px;
		margin-left: 10px;
		padding-top: 37px;
		float: left;
		@media screen and (max-width: $bp-mobile) {
			margin: 5px 0 0;
			padding: 0;
		}
	}
}

.menu-icon {
	button {
		display: none;
		position: relative;
		overflow: hidden;
		margin: 0;
		padding: 0;
		width: 30px;
		height: 25px;
		font-size: 0;
		text-indent: -9999px;
		border: none;
		cursor: pointer;
		background-color: #181818;
		@media screen and (max-width: $bp-mobile) {
			display: block;
		}
		
		&:focus {
			outline: none;
		}
		span {
			display: block;
			position: absolute;
			top: 10px;
			left: 0px;
			right: 0px;
			height: 2px;
			background: white;
			&:before, &:after {
				position: absolute;
				display: block;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: #fff;
				content: "";
			}
			&:before {
				top: -8px;
				transition-property: top, transform;
			}
			&:after {
				bottom: -8px;
				transition-property: bottom, transform;
			}
		}
		&.close {
			span {
				background: none;
				&:before {
					top: 0;
					transform: rotate(45deg);
				}
				&:after {
					bottom: 0;
					transform: rotate(-45deg);
				}
			}
		}
	}
}

.header {
	margin-bottom: 20px;
	.header-inner {
		padding: 20px 0;
		border-bottom: 1px solid $greylight;
		display: block;
		position: relative;
		@media screen and (max-width: $bp-mobile) {
		}
	}
	.nav-outer {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		padding-left: 130px;
		@media screen and (max-width: $bp-mobile) {
			position: initial;
			top: inherit;
			transform: none;
			padding-left: 0;
		}
		
	}
	.nav-wrap {
		@media screen and (max-width: $bp-mobile) {
			width: 100%;
			background-color: rgba(67, 67, 66, 0);
			padding: 0;
			display: none;
		}
		&.open {
			right: 5px;
			@media screen and (max-width: $bp-mobile) {
				display: block;
				margin-top: 20px;
				margin-bottom: -20px;
				li {
					text-align: center;
					margin: -1px 0;
					
					&:first-child {
						margin-top: 0px;
					}
					
					a {
						font-size: 28px;
						font-weight: normal;
						padding: 10px 0;
						width: 100%;
						border-top: 1px solid $greylight;
						border-right: 0px solid $greylight;
						border-bottom: 1px solid $greylight;
						border-left: 0px solid $greylight;
						color: #ffffff;
						
						&:last-of-type {
							border-bottom: none;
						}
						
						&:hover {
							//border: 1px solid $grey;
							background-color: rgba(0, 0, 0, 0.2);
							color: $tech;
						}
						
						&,
						&:hover,
						&:active,
						&:focus {
							outline: 0;
						}
					}
					
					&.current_page_item {
						
						a {
							background-color: rgba(0, 0, 0, 0.2);
							border-top: 1px solid $greylight;
							border-right: 0px solid $greylight;
							border-bottom: 1px solid $greylight;
							border-left: 0px solid $greylight;
						}
						
						/*a {
							color: #ffffff !important;
							border: 1px solid $grey;
						}*/
					}
				}
			}
		}
	}
	.menu-icon {
		position: absolute;
		right: 0;
		@media screen and (max-width: 1200px) {
			border-right: 15px solid #181818;
		}
		@media screen and (max-width: $bp-mobile) {
			top: 70px;
		}
	}
}

.nav-container {
	ul {
		text-align: right;
		list-style-type: none;
		padding: 0;
		margin: 0;
		@media screen and (max-width: 750px) {
			text-align: right;
			padding: 0;
			li:first-child a {
				margin-left: 0;
				padding-left: 0;
			}
		}
		@media screen and (max-width: $bp-mobile) {
			margin: 0;
		}
		li {
			display: inline-block;
			@media screen and (max-width: $bp-mobile) {
				display: block;
				margin-bottom: 3px;
				text-transform: uppercase;
			}	
			a {
				padding: 0;
				margin: 0 15px 10px;
				display: inline-block;
				color: #fff;
				text-transform: uppercase;
				font-size: 20px;
				letter-spacing: 0;
				font-family: $patua;
				@media screen and (max-width: $bp-mobile) {
					margin: 0;
				}
				&:hover {
					color: $tech;
				}
			}
			&.current-menu-item {
				a {
					color: $tech;
				}
			}
		}
	}
}
