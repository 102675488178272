// Base styles
// Setup your baseline, selection and links
// =================================================================================

/* 	SELECTION
	----------------- */
@include selection($black, $white);


/* 	LINKS
	----------------- */
a {
	color: $linkColor;
	text-decoration: none;
	&:visited {
		color: $linkColor;
	}
	&:hover {
		color: $linkHover;
		text-decoration: none;
	}
	&:focus {
		outline: thin dotted;
	}
}

div {	
	box-sizing: border-box;
}