.footer-wrap {
	background-color: #000;

	padding-top: 20px;
}

.footer {
	color: #706F6F;
	font-size: 14px;
	margin: 20px 0;
	ul {
		margin: 0 0 10px;
		padding: 0;
		list-style-type: none;
		li {
			margin: 0 0 0;
			display: inline;
			&:after {
				content: "\00a0\00a0|\00a0\00a0";
				@media screen and (max-width: 460px) {
					content: "";
				}
			}
			&:last-child:after {
				content: "";
			}
			@media screen and (max-width: 460px) {
			display: block;
			}
		}
	}
	a {
		color: #706F6F;
		display: inline-block;
		vertical-align: middle;
	}
	.social-icons {
		text-align: right;
		margin-top: -10px;
		img {
			width: 35px;
			display: block;
		}
	}
}

.footer .twitter-widget {
	li {
		margin: 0 0 5px;
	}
	.entry-meta {
		display: none;
	}
}
