// Main styles
// Our main development file
// =================================================================================

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

::selection {
	background-color: rgba(87,87,86,.99)
}
::-moz-selection {
	background: $grey; /* Firefox */
}

::-webkit-selection {
    background: $grey;
}

a {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	color: #B9BE0F;
    text-decoration: none;
    outline: 0;
    
    &:visited {
	   color: #B9BE0F;
    }
    &:hover {
	    color: #9D9D9C;
    }
    
    &:hover,
    &:visited,
    &:focus,
    &:link {
	    outline: 0;
    }
}

body {
	background-color: #181818;
	color: $gray;
	font-family: "acumin-pro",sans-serif;
	font-size: 16px;
	line-height: 140%;
}

h1 {
	font-weight: normal;
	font-family: $patua;
	margin: 0 0 15px;
	color: $tech;
}

h2, h3, h4, h5 {
	font-family: $patua;
	color: $tech;
}

h3 {
	font-size: 20px;
}

hr {
	border: 0;
	height: 1px;
	background-color: #6f6f6e;
	margin: 0;
}

ul, ol {
	padding: 0 0 0 18px;
}

hr.two-pix {
		height: 2px;
}

img {
	height: auto;
}

embed,
iframe,
object {
	max-width: 100%;
	width: 100%; 
}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
    margin: 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float:right;
    margin: 5px 0 20px 20px;
    @media screen and (max-width: 400px) {
		display: block;
		float: none;
	}
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
    @media screen and (max-width: 400px) {
		display: block;
		float: none;
	}
}

.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.image_align_centre {
    margin: 0px;
    padding: 5px;
	vertical-align:middle;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.wp-caption {
    background: #fff;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 0px;
    text-align: center;
    color: #000;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 13px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}

.criticalwarning {
   color: #ff0000;
}

@media screen and (max-width: 768px) {
	tr td {
		font-size: 10px;
	}
}

////////

.wrapper {
	max-width: 1000px;
	margin: 0 auto;
	@media screen and (max-width: 1200px) {
		padding: 0 15px 0 15px;
	}
}

input {
	width: 100%;
	box-sizing: border-box;
	border: 0;
	padding: 5px;
	font-size: 14px;
}

input[type="submit"] {
	cursor: pointer;
}


body {
	@media screen and (max-width: $bp-mobile) {
		&.overflow {
			height: 100%;
			overflow: hidden;
		}
	}
}

.hidden {
    display: none !important;
    visibility: hidden;
}

.page-id-9 {
	.main {
		ul {
			list-style-type: none;
			padding: 0;
		}
	}
}


.single-post {
	h1 {
		margin: 25px 0 0;
		font-family: $limefont;
	}
	p {
		font-size: 16px;
	}
	a {
		-ms-word-break: break-all;

		     /* Be VERY careful with this, breaks normal words wh_erever */
		     word-break: break-all;
		
		     /* Non standard for webkit */
		     word-break: break-word;
		
		-webkit-hyphens: auto;
		   -moz-hyphens: auto;
		        hyphens: auto;
	}
}

.wpcf7 {
	/*width: 30%;*/
}

body.page-template-page-contact-php .main {
	.wpcf7 {
		font-family: $arial;
		p {
			margin: 0 0 10px;
			&.lable {
				margin: 0 0 3px;
			}
		}
		textarea {
			border: 0;
			padding: 5px;
			box-sizing: border-box;
			font-family: $arial;
			font-size: 14px;
			-webkit-appearance: none;
		    border-radius: 0;
		    background-color: #434342;
		    color: #9D9D9C;
		}
		input {
			font-family: $arial;
		    -webkit-appearance: none;
		    border-radius: 0;
		    background-color: #434342;
		    color: #9D9D9C;
		}
		input[type="submit"] {
			background: #BABF10 url('../img/right-arrow-button.png') 95% 50% no-repeat;
			color: #fff;
			width: 70px;
			float: right;
			text-align: left;
			padding: 5px 5px 5px 10px;
		}
		.left {
			float: left;
			width: 65%;
			img {
				float: right;
			}
			p {
				@include clearfix;
				margin: 0 0;
			}
		}
		.right {
			width: 35%;
			vertical-align: bottom;
			float: right;
			padding-top: 23px;
			@include clearfix;
		}
		.submit-wrap {
			@include clearfix;
		}
		div.wpcf7-response-output {
			margin: 20px 0;
		}
		div.wpcf7-validation-errors {
			border: none;
			background-color: red;
			padding: 5px;
		}
		img.ajax-loader {
			margin-right: 5px;
		}
		div.wpcf7-mail-sent-ok {
			border: none;
			background-color: #398F14;
			padding: 5px;
		}
	}
}

.dark.dp_pec_wrapper .dp_pec_content div.dp_pec_date {
	background-image: none;
	&.with-event {
		background-color: green;
		background-image: none;
		.dp_count_events {
			background-color: green;
			color: #fff;
		}
		.dp_date_head {
			background-color: green;
			color: #fff;
			span {
				color: #fff;
			}
		}
	}	
}

span.black-yellow {
	color: #000;
	background-color: #FFFF00;
	padding: 3px;
}

span.white-red {
	color: #fff;
	background-color: #FF0000;
	padding: 3px;
}

.dark.dp_pec_wrapper .dp_pec_content .dp_pec_date

.dp_pec_wrapper.dp_pec_400 .dp_pec_content .dp_pec_date_event h1.dp_pec_event_title {
	font-size: 14px; 
}

.dp_pec_wrapper.dp_pec_400 .dp_pec_content .dp_pec_date_event p.dp_pec_event_description {
	font-size: 14px;
}

.dp_pec_wrapper .dp_pec_nav .selectricWrapper {
	margin-right: 10px;
}

.dp_pec_wrapper .dp_pec_content .dp_pec_date_event h1.dp_pec_event_title a:hover {
	color: $tech !important;
}

.dark.dp_pec_wrapper .dp_pec_content .dp_pec_date_event a.dp_pec_date_event_link {
	margin-top: 0;
	width: auto;
	height: auto;
	background: transparent;
}

.sidebar {
	h3 {
		margin: 0 0 5px;
		font-family: $patua;
		font-weight: normal;
		font-size: 20px;
		text-transform: uppercase;
		+ p {
			margin: 0;
		}
	}
	.dark.dp_pec_wrapper {
		background-color: transparent;
		border: none;
		margin: 0;
		box-shadow: none;
		.dp_pec_content {
			margin: 0;
			.dp_pec_date_event span.dp_pec_date_event_time {
				color: $tech;
				font-size: 13px;
			}
		}
	}
}

.home-banner {
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid $greylight;
}

.awesome-weather-wrap {
	@media screen and (max-width: $bp-mobile) {
		margin-bottom: 30px;
	}
}
