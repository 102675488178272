// Variable
// Setup your global variable
// =================================================================================

// Grid & baseline setup
// ------------------
$base-font-size		: 16px;
$base-line-height	: 24px;

// Global Palettes
// ------------------
$black				: #000 !default;
$blackLight			: #222 !default;
$blackLighter		: #555 !default;
$grayDarker			: #999 !default;
$grayDark			: #aaa !default;
$gray				: #ccc !default;
$grayLight			: #ddd !default;
$grayLighter		: #eee !default;
$white				: #fff !default;
$whiteDark			: #f5f5f5 !default;
$whiteDarker		: #f0f0f0 !default;

$lime : #babf10;
$pink : #E70094;
$grey : #575756;
$greylight : #6f6f6e;
$tech : #F7B900;

// Layout Palettes
// -------------------------
$backgroundColor	: $white !default;
$borderColor		: $grayLighter !default;
$fontColor			: $blackLighter !default;
$altColor			: $gray !default;

// Link Palettes
// -------------------------
$linkColor			: #00baff !default;
$linkHover			: darken( $linkColor, 10% ) !default;
$linkVisited		: darken( $linkHover, 5% ) !default;
$linkFocus			: $linkHover !default;
$linkActive			: lighten( $linkColor, 10% ) !default;

$limefont			: 'Lexia', serif !default;


// Breakpoints
// -------------------------
$bp-mobile			: 540px;
$bp-tab				: 768px;

@import url('https://fonts.googleapis.com/css?family=Patua+One');

// Sans-Serif font-stacks
// -------------------------

$arial	: Arial, "Helvetica Neue", Helvetica, sans-serif !default;
$patua	: 'Patua One', cursive;
